import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { RestaurantComponent } from './Page/restaurant/restaurant.component';
import { MQTTservicesService } from './Services/mqttservices.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.hostName,
  path: environment.mqtt.path,
  protocol: environment.mqtt.protocol === "wss" ? "wss" : "ws",
  clean: environment.mqtt.clean,
  connectTimeout: environment.mqtt.connectTimeout,
  clientId: environment.mqtt.clientId,
  username: environment.mqtt.userName,
  password: environment.mqtt.password,
  reconnectPeriod: environment.mqtt.reconnectPeriod,
  keepalive: environment.mqtt.keepalive
};
@NgModule({
  declarations: [
    AppComponent,
    RestaurantComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    AppRoutingModule
  ],
  providers: [MQTTservicesService],
  bootstrap: [AppComponent]
})
export class AppModule { }

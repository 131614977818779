<div [ngStyle]="{ display: !isLoading ? '' : 'none' }" class="container">
    <div class="row mt-3">
        <div class="col-md-8 col-12">
            <div class="shimmerBG media"></div>
        </div>
        <div class="col-md-4 col-12">
            <div class="">
                <div class="row mt-3">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3 col-sm-3 col-3">
                        <div class="shimmerBG title-line end"></div>
                    </div>
                    <div class="col-md-9 col-9">
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                        <div class="shimmerBG content-line"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6 col-12">
            <div class="shimmerBG title-line"></div>
        </div>
        <div class="col-md-6 col-12">
            <div class="shimmerBG title-line"></div>
        </div>
    </div>
</div>

<div [ngStyle]="{ display: isBlur ? '' : 'none' }" class="fix_content_wrap">
    <div class="fixed_content text-center">
        <h1>Uh-oh!</h1>
        <label>Sorry! This should not have happened. Please retry.</label>
        <br />
        <button class="btn pl-4 pr-4 btn-light" (click)="refresh()">
            Retry
        </button>
    </div>
</div>

<!-- <div class="travel_duration">
    <div *ngIf="statusFlag == 2">
        Driver reaching in {{ distanceMinute }}
    </div>
    <div *ngIf="statusFlag == 3">
        Reaching destination in {{ distanceMinute }}
    </div>
</div> -->

<div class="googleMapStyle" [ngStyle]="{ display: !isLoading ? 'none' : '' }"
    [ngClass]="{ bookingHide: bottomSheet === true }" [ngStyle]="{ filter: isBlur ? 'blur(5px)' : 'blur(0px)' }">
    <div [hidden]="isCompletedBooking" class="map" id="restMap" class="match-parent"></div>
    <div class="sticky"
        [ngStyle]="{ display: bottomSheet ? 'none' : '', 'margin-top': isCompletedBooking ? '' : '65vh' }">
        <hr class="lineIcon" [ngStyle]="{ display: !bottomSheet ? 'none' : ''}" />
    </div>
    <div class="bookingAndDriverDetailMainBox" [ngStyle]="{ display: bottomSheet ? 'none' : '' }">
        <div *ngIf="isLoading" class="BookingDetailsMainDivShow" id="BookingDetailsMainDivHideShow">
            <div class="driverDetails">
                <div class="driverCard">
                    <div class="card">
                        <div class="imgs">
                            <img src="{{ driverImage }}" loading="lazy">
                        </div>
                        <div class="infos">
                            <div class="name">
                                <h2> {{
                                    driverDetails?.driverName ? driverDetails?.driverName : "N/A"
                                    }}</h2>
                                <h4>
                                    <a class="fnt"
                                        href="tel:{{ driverDetails?.driverPhone ? driverDetails?.driverPhone.startsWith('+') ? '' : '+' + driverDetails?.driverPhone : 'N/A' }}"><i
                                            class="fa fa-phone"></i>
                                        <span>&nbsp;&nbsp;{{ driverDetails?.driverPhone ?
                                            driverDetails?.driverPhone.startsWith('+') ? '' :
                                            '+' + driverDetails?.driverPhone : "N/A" }}</span>
                                    </a>
                                </h4>
                            </div>
                            <div class="text">
                                <div class="fnt">
                                    <span>Order ID: </span><span class="fnt-weight"> {{ bookingId }}</span>
                                </div>
                                <div class="fnt">
                                    <span>Order No: </span><span class="fnt-weight"> {{ bookingDetails?.orderNo
                                        }}</span>
                                </div>
                                <p class="fnt" *ngIf="bookingDetails?.isDestinationLater != 1">
                                    Est. Delivery Time :
                                    <span class="fnt-weight">
                                        {{
                                        moment(bookingDetails?.deliveryDateTime,'YYYY-MM-DD HH:mm:ss').format('HH:mm')
                                        }} Min
                                    </span>
                                </p>
                            </div>
                            <ul class="stats">
                                <li>
                                    <h3>
                                        <p class="fnt">Car Type :</p>
                                    </h3>
                                    <h3>
                                        <p class="fnt">License Plate :</p>
                                    </h3>
                                    <h3>
                                        <p class="fnt">Car Model :</p>
                                    </h3>
                                </li>
                                <li>
                                    <h4 class="fnt">
                                        {{ carDetails?.carNumberAndType ? carDetails?.carNumberAndType : "---" }}
                                    </h4>
                                    <h4 class="mt-3 fnt">
                                        {{ carDetails?.carNumberPlate ? carDetails?.carNumberPlate : "---" }}
                                    </h4>
                                    <h4 class="mt-3 fnt">
                                        {{ carDetails?.carModalAndColor ? carDetails?.carModalAndColor : "---" }}
                                    </h4>
                                </li>
                            </ul>
                            <div class="links">
                                <div class="mapPin">
                                    <img src="../../../assets/img/location.png" loading="lazy">
                                </div>
                                <a target="_blank" style="margin-left: 4px;"
                                    href="http://maps.google.com/?q={{ dropLatLng?.lat + ',' + dropLatLng?.lng}}"
                                    title="{{ dropLatLng?.address }}" class="ec giveEllipsis"
                                    [style.color]="firstColor">
                                    {{ dropLatLng?.address }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="wrapper">
                <div class="trackingBox">
                    <div class="">
                        <img class="trackIcons" src="../../assets/img/rest/orderCreated.png" alt="">
                    </div>
                    <div class="trackText">
                        <div>
                            <strong>Order Created</strong>
                        </div>
                        <div>
                            Your order has been placed successfully at {{
                            moment(bookingDetails?.bookCreateDateTime,'YYYY-MM-DD
                            HH:mm:ss').format('DD MMM HH:mm') }}.
                            The restaurant is preparing your food.
                        </div>
                    </div>
                    <div class="boxBorder">
                    </div>
                </div>
                <div class="trackingBox" *ngIf="bookingDetails?.acceptedTime !=''">
                    <div class="">
                        <img class="trackIcons" src="../../assets/img/rest/driver_accept.png" alt="">
                    </div>
                    <div class="trackText">
                        <div>
                            <strong>Driver Accepted</strong>
                        </div>
                        <div>
                            Hang tight, the driver is on the way to the restaurant to get your food.
                            <div>
                                {{ moment(bookingDetails?.acceptedTime,'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm') }}
                            </div>
                        </div>
                    </div>
                    <div class="mobile_driver_info">
                        <div class="driverBox">
                            <div class="driverImg">
                                <img src="{{ driverImage }}" height="40px" loading="lazy">
                            </div>
                            <div class="call_driver">
                                <a class="fnt"
                                    href="tel:{{ driverDetails?.driverPhone ? driverDetails?.driverPhone.startsWith('+') ? '' : '+' + driverDetails?.driverPhone : 'N/A' }}">
                                    <img src="../../assets/img/rest/callDriver.png" alt="" height="30px">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="boxBorder">
                    </div>
                </div>
                <div class="trackingBox" *ngIf="bookingDetails?.arrivedTime  !=''">
                    <div class="">
                        <img class="trackIcons" src="../../assets/img/rest/ArrivedAtRst.png" alt="">
                    </div>
                    <div class="trackText">
                        <div>
                            <strong>Driver At Restaurant</strong>
                        </div>
                        <div>
                            The driver has arrived at the restaurant.
                            <div>
                                {{
                                bookingDetails?.arrivedTime
                                ? moment(bookingDetails?.arrivedTime,'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm')
                                : "Driver Will Arrived Restaurant Soon"
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="boxBorder">
                    </div>
                </div>
                <div class="trackingBox" *ngIf="bookingDetails?.beginTime  !='' ">
                    <div class="">
                        <img class="trackIcons" src="../../assets/img/rest/onRoute.png" alt="">
                    </div>
                    <div class="trackText">
                        <div>
                            <strong>Order Picked Up</strong>
                        </div>
                        <div>
                            <span>
                                Almost there! {{ driverDetails?.driverName ? driverDetails?.driverName : "N/A" }} has
                                picked up your
                                order and is on the way.</span>
                        </div>
                        <div>
                            {{
                            bookingDetails?.beginTime
                            ? moment(bookingDetails?.beginTime,'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm')
                            : "Driver Will Arrived Restaurant Soon"
                            }}
                        </div>
                    </div>
                    <div class="boxBorder">
                    </div>
                </div>
                <div class="trackingBox" *ngIf="bookingDetails?.dropTime !=''">
                    <div class="">
                        <img class="trackIcons" src="../../assets/img/rest/icons8-food-delivered-100.png" alt="">
                    </div>
                    <div class="trackText">
                        <div>
                            <strong>Order Arrived</strong>
                        </div>
                        <div>
                            <span>Hurray! {{ driverDetails?.driverName ? driverDetails?.driverName : "N/A" }} has
                                arrived at your
                                destination.
                            </span>
                        </div>
                        <div>
                            {{
                            bookingDetails?.dropTime
                            ? moment(bookingDetails?.dropTime,'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm')
                            : "Driver Will Arrived at You Soon"
                            }}
                        </div>
                    </div>
                    <div class="boxBorder">
                    </div>
                </div>
                <div class="trackingBox" *ngIf="bookingDetails?.completeTime && bookingDetails?.completeTime !=''">
                    <div class="">
                        <img class="trackIcons" src="../../assets/img/rest/est.completed.png" alt="">
                    </div>
                    <div class="trackText">
                        <div>
                            <strong>Order Completed</strong>
                        </div>
                        <div>
                            <span>Driver {{ driverDetails?.driverName ? driverDetails?.driverName : "N/A" }} has
                                delivered your order
                                on {{
                                moment(bookingDetails?.completeTime,'YYYY-MM-DD HH:mm:ss').format('DD MMM HH:mm')
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading">
            <div class="card">
                <div class="shimmerBG media"></div>
                <div class="p-32">
                    <div class="shimmerBG title-line"></div>
                    <div class="shimmerBG title-line end"></div>

                    <div class="shimmerBG content-line m-t-24"></div>
                    <div class="shimmerBG content-line"></div>
                    <div class="shimmerBG content-line"></div>
                    <div class="shimmerBG content-line"></div>
                    <div class="shimmerBG content-line end"></div>
                </div>
            </div>
        </div>
    </div>
</div>
export const environment = {
  production: true,


  // OPEN_STREET_HOST: '167.99.50.184',  //Development
  // OPEN_STREET_URL: 'https://development.wiyak.com/openstreet/', //Development
  // OPEN_STREET_URL: 'https://productionv4.rideplus.in/openstreet/', //Production
  // OPEN_STREET_URL: 'https://productionv4.voilacabs.com/openstreet/', //Production   
  OPEN_STREET_URL: 'https://production.wiyak.delivery/openstreet', //Delivery Production   
  OPEN_STREET_PORT: 5000,
  OPEN_STREET_HOST: '13.200.147.250', //Production chois

  // ! Development mode
  // mapbox: {
  //   accessToken: [
  //     'pk.eyJ1IjoiaGluYXRhbmsxMSIsImEiOiJjbG1yZ25qMGcwNjc1MmptcmdpbTF1dW5hIn0.R9hqO42aSc6nb8zd9esVCQ'
  //   ]
  // },

  // ! Production Mode Delivery
  mapbox: {
    accessToken: [
      'pk.eyJ1IjoicnVwZW5td2l5YWsiLCJhIjoiY2xzNG9jamEwMWNzNzJrcXAwaXZ3eHcwZiJ9.P3SL9Pj5xEYn9GaziIWnNA',
      'pk.eyJ1Ijoia2hhbGVkd2l5YWsiLCJhIjoiY2xzNG9xNGhuMTNzaTJsb2V2d2d6bDJ5OSJ9.XkgeVZ0CyXLvVzNm7AHy6w'
    ]
  },


  // ! V4 Prod
  // mapbox: {
  //   accessToken: [
  //     'pk.eyJ1IjoicnVwZW5tIiwiYSI6ImNsc3N5MGp2dzE2dXUybHFteHQ1aWV3OTYifQ.j0LAPo2iMXxqebCm58Xqew'
  //   ]
  // },


  //! ====== development ============
  // baseUrl: 'https://development.wiyak.com/WiyakNode/',
  // // apiKey: "AIzaSyA9XFUwLGqTtynnEImJ5myZ1n1w_ykSzWg", //Old API key used
  // apiKey: 'AIzaSyCMyRf7I3lwZsKGMsaTA83NjXsROl-nnlY', //New wfleet API key used
  // mqtt: {
  //   hostName: 'mqtt.wiyak.com',
  //   path: '/mqtt',
  //   protocol: 'wss',
  //   clean: true,
  //   connectTimeout: 4000,
  //   clientId: 'dispatchermqtt_' + Math.random().toString(16).substr(2, 8),
  //   userName: 'wiyakkuwait',
  //   password: '0C46507DF16A0B4B4A25FC7FCC1D47B7wiyakkuwait',
  //   reconnectPeriod: 1000,
  //   keepalive: 65535
  // },
  // ForceApiKey:
  //   '40770da6eaeb1d7e442f8b483fb49e088e72dc5113cb69637510553697035930',

  // ! ================================================================= 


  // ! ============================================== Wiyak Production =================================================================
  // baseUrl: "https://production.wiyak.com/WiyakNode/",
  // // apiKey: "AIzaSyAHthCElvTGw5ydnmZhZis467vZj5LZABo", - Old one 
  // // apiKey: "AIzaSyCiKTuA_q6bvjL6BG4WWpPA4DK9x-cO-rU", // - new - wiyaktechsuperstar1@gmail.com
  // apiKey: "AIzaSyBN2UgGIj2R0JS8tT6eoU0QK6rBP6uMcNw", // - new - wiyaktechsuperstar1@gmail.com
  // mqtt: {
  //   hostName: "productionmqtt.wiyak.com",
  //   path: "/mqtt",
  //   protocol: "wss",
  //   clean: true,
  //   connectTimeout: 4000,
  //   clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 8),
  //   userName: "wiyak_prod",
  //   password: "9d931d3e268934848c781c547fe12d699b483e23",
  //   reconnectPeriod: 1000,
  //   keepalive: 65535
  // },
  // ForceApiKey: "40770da6eaeb1d7e442f8b483fb49e088e72dc5113cb69637510553697035930",




  // !=========================================== Chois Production  =======================================================
  // baseUrl: "https://productionv4.rideplus.in/voilaChois/",
  // apiKey: "AIzaSyCMyRf7I3lwZsKGMsaTA83NjXsROl-nnlY", // - new - choistaxi@gmail.com
  // mqtt: {
  //   hostName: "mqtt.rideplus.in",
  //   path: "/mqtt",
  //   protocol: "wss",
  //   clean: true,
  //   connectTimeout: 4000,
  //   clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 8),
  //   userName: "admin",
  //   password: "xRtbMMo4Wb14pHFPU3yCd5qm67TisyOwyZNOo4iZA4PtXQ7mK35tuyN",
  //   reconnectPeriod: 1000,
  //   keepalive: 65535
  // },
  // ForceApiKey: "4ji9RJQJCWZLRq0vV7RuaupqqO0shxWogK8n7NyD5QoBj4dOBs",




  // ! ================== Wiyak Delivery =================================
  baseUrl: "https://production.wiyak.delivery/WiyakDelivery/",
  apiKey: "AIzaSyA9XFUwLGqTtynnEImJ5myZ1n1w_ykSzWg",
  mqtt: {
    hostName: "mqtt.wiyak.delivery",
    path: "/mqtt",
    protocol: "wss",
    clean: true,
    connectTimeout: 4000,
    clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 8),
    userName: "wiyak_delivery_prod",
    password: "x2yVVNqCtxLMHdbkkwU7sWWDXVvt6VwwK6oORwuY",
    reconnectPeriod: 1000,
    keepalive: 65535
  },
  ForceApiKey:
     '40770da6eaeb1d7e442f8b483fb49e088e72dc5113cb69637510553697035930',

  // ! ========================= V4 =========================
  // baseUrl: "https://productionv4.voilacabs.com/voilaSaas/",
  // apiKey: "AIzaSyA9XFUwLGqTtynnEImJ5myZ1n1w_ykSzWg",
  // mqtt: {
  //   hostName: "mqttv4.voilacabs.com",
  //   path: "/mqtt",
  //   protocol: "wss",
  //   clean: true,
  //   connectTimeout: 4000,
  //   clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 8),
  //   userName: "voila_v_four_prod",
  //   password: "DcEGtTwNXjaDkCVL3KFAKsHKVSJdm5FoA6xmd4cf8iLLLVQMLs048wwzJGl0",
  //   reconnectPeriod: 1000,
  //   keepalive: 65535
  // },
  // ForceApiKey: "4ji9RJQJCWZLRq0vV7RuaupqqO0shxWogK8n7NyD5QoBj4dOBs",


  // accessToken: "pk.eyJ1IjoicnVwZW5td2l5YWsiLCJhIjoiY2xzNG9jamEwMWNzNzJrcXAwaXZ3eHcwZiJ9.P3SL9Pj5xEYn9GaziIWnNA" //Rupen Sir Wiyak
  // accessToken: "pk.eyJ1Ijoia2hhbGVkd2l5YWsiLCJhIjoiY2xzNG9xNGhuMTNzaTJsb2V2d2d6bDJ5OSJ9.XkgeVZ0CyXLvVzNm7AHy6w" //Khaled Sir Wiyak



  // ! ========================= V4 ========================= 
  // baseUrl: "https://productionv4.voilacabs.com/voilaSaas/",
  // baseUrl: "https://productionv4.voilacabs.com/voilaInd/",
  // apiKey: "AIzaSyA9XFUwLGqTtynnEImJ5myZ1n1w_ykSzWg",
  // mqtt: {
  //   hostName: "mqttv4.voilacabs.com",
  //   path: "/mqtt",
  //   protocol: "wss",
  //   clean: true,
  //   connectTimeout: 4000,
  //   clientId: "dispatchermqtt_" + Math.random().toString(16).substr(2, 8),
  //   userName: "voila_v_four_prod",
  //   password: "DcEGtTwNXjaDkCVL3KFAKsHKVSJdm5FoA6xmd4cf8iLLLVQMLs048wwzJGl0",
  //   reconnectPeriod: 1000,
  //   keepalive: 65535
  // },
  // ForceApiKey: "4ji9RJQJCWZLRq0vV7RuaupqqO0shxWogK8n7NyD5QoBj4dOBs",


};

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly configUrl = environment.baseUrl;
  private readonly imageUrl = `${environment.baseUrl}media/img/fetch/`;
  private readonly driverFolderName = 'Driver/';
  private readonly carFolderName = 'Cars/';
  private readonly companyFolderName = 'CompanySetting/';


  statusFlags: any = {
    'ACCEPT_TRIP': 1,
    'ARRIVED_AT_RESTAURANT': 2,
    'ON_ROUTE_RESTAURANT': 3,
    'ARRIVED_AT_CUSTOMER': 4,
    'COMPLETE_ORDER': 5,
    'CANCEL_ORDER': 6,
    'TRANSFER_TO_DRIVER': 7,
    'EDIT_ORDER': 8,
    'FORCEFULLY_DELIVERED': 9,
    'FORCEFULLY_CHANGE_STATUS': 10,
    'DRIVER_REJECT_BOOKING': 11,
    'FAIL_TO_FIND_NEAREST_DRIVER_RESTAURANT': 12,
    'UNAVAILABLE_ORDER': 13
  } as const

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { }

  getBooking(bookingId: string) {
    const data = { bookingId };
    const url = `${this.configUrl}track/ride/booking/get`;
    return this.http.post(url, data, this.httpOptions);
  }

  getRestaurantBooking(bookingId: string) {
    const data = { bookingId };
    const url = `${this.configUrl}track/restaurant/order/get`;
    return this.http.post(url, data, this.httpOptions);
  }

  getDirectionFromMapBox(opts: any) {
    const params = new HttpParams()
      .set('steps', opts.steps ? 'true' : 'true')
      .set('alternatives', opts.alternatives ? 'true' : 'false')
      .set('overview', opts.overview || 'full');

    let url = "";
    if (environment.production) {
      // ! Production mode
      url = `${environment.OPEN_STREET_URL}/route/v1/driving/${opts}`;
    }
    else {
      //! Development mode  
      url = `https://${environment.OPEN_STREET_HOST}:${environment.OPEN_STREET_PORT}/route/v1/driving/${opts}`;
    }
    console.log('url', url);
    return this.http.get(url, { headers: this.httpOptions.headers, params });
  }
}














// import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//   }),
// };

// @Injectable({
//   providedIn: 'root',
// })
// export class ApiService {
//   constructor(private http: HttpClient) { }

//   configUrl = environment.baseUrl;
//   imageUrl = environment.baseUrl + 'media/img/fetch/';
//   driverFolderName = 'Driver/';
//   carFolderName = 'Cars/';
//   companyFolderName = 'CompanySetting/';

//   getBooking(bookingId: string) {
//     let data = { bookingId: bookingId };

//     let header = new HttpHeaders({
//       'Content-Type': 'application/json',
//     });
//     console.log('header', header);

//     let url = this.configUrl + 'track/ride/booking/get';

//     return this.http.post(url, data, { headers: header })
//   }

//   getDirectionFromMapBox(opts: any) {
//     let header = new HttpHeaders({
//       'Content-Type': 'application/json',
//     });

//     // Set up additional query parameters
//     const params = new HttpParams()
//       .set('steps', opts.steps ? 'true' : 'true')
//       .set('alternatives', opts.alternatives ? 'true' : 'false')
//       .set('overview', opts.overview || 'full'); // Provide a default value if overview is not provided

//     // ! production
//     // let url = `${environment.OPEN_STREET_URL}route/v1/driving/${opts}`;

//     // ! Development
//     let url = `http://${environment.OPEN_STREET_HOST}:${environment.OPEN_STREET_PORT}/route/v1/driving/${opts}`;
//     console.log('url', url);
//     return this.http.get(url, { headers: header, params: params });
//   }



// }

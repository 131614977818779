import { Injectable } from '@angular/core';
// import KalmanFilter from 'kalmanjs';
@Injectable({
  providedIn: 'root'
})
export class KalmanFilterService {


  constructor() {
    // Initialize with default values

  }

  calculateVariance(array: any) {
    const mean = array.reduce((a: any, b: any) => a + b, 0) / array.length;
    const variance = array.reduce((a: any, b: any) => a + Math.pow(b - mean, 2), 0) / (array.length - 1);
    return variance;
  }


  filterLatLongs(latLonArray: any) {
    var KalmanFilter = require('kalmanjs')
    // console.log('--------------------- latLonArray ---------------------', latLonArray)

    // console.log('--------------------- latLonArray.filter((data) => data.lat != undefined) ---------------------', latLonArray.filter((data) => data.lat != undefined))


    const latArray = latLonArray.map((data: any) => (data !== undefined) ? data.latitude : false).filter((data: any) => data !== false)
    const lonArray = latLonArray.map((data: any) => (data !== undefined) ? data.longitude : false).filter((data: any) => data !== false)


    const variationLat = this.calculateVariance(latArray);
    const variationLon = this.calculateVariance(lonArray);


    const kalmanFilterLat = new KalmanFilter({ R: variationLat, Q: 0.001 });
    const kalmanFilterLon = new KalmanFilter({ R: variationLon, Q: 0.001 });



    return latLonArray.map((element: any) => {
      if (element !== undefined) {
        const filteredLat = kalmanFilterLat.filter(element.latitude);
        const filteredLon = kalmanFilterLon.filter(element.longitude);
        return { latitude: filteredLat, longitude: filteredLon };
      }else {
        // Return a default object if `element` is undefined
        return { latitude: 0, longitude: 0 }; // or another default value as appropriate
      }
    })

  }

}
